import { ComponentPropsWithRef, ComponentPropsWithoutRef, forwardRef } from 'react';

export const DivContentNews = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(({ children, ...rest }, ref) => {
  return (
    <div id="content-news" ref={ref} {...rest}>
      {children}
    </div>
  );
});

export const TableContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="mb-6 overflow-x-auto" {...rest}>
      {children}
    </div>
  );
};

export const Ad = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="relative mb-4 flex w-full justify-center p-0 text-center" {...rest}>
      {children}
    </div>
  );
};

export default {};
