/* eslint-disable import/prefer-default-export */
import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  ContainerImage: {
    editorialCategory: {
      true: 'editorialCategory [&_img]:[&.editorialCategory]:rounded-t-none [&.editorialCategory]:rounded-t-none',
      false: '',
    },
    isHeader: {
      internal: '[&_img]:aspect-video',
      full: '[&_img]:aspect-video [&_img]:tablet:aspect-[3/1]',
    },
  },
} as const;

export const ContainerImage = ({
  children,
  editorialCategory,
  isHeader,
  className,
  ...rest
}: ComponentPropsWithoutRef<'figure'> & { editorialCategory?: boolean; isHeader?: 'internal' | 'full' }) => (
  <figure
    className={classNames(
      'relative h-full w-full overflow-hidden rounded [&_canvas]:block [&_canvas]:aspect-video [&_canvas]:h-full [&_canvas]:w-full [&_canvas]:rounded [&_img]:block [&_img]:aspect-video [&_img]:h-full [&_img]:w-full [&_img]:rounded [&_img]:object-cover',
      editorialCategory != null && variants.ContainerImage.editorialCategory[editorialCategory.toString()],
      isHeader != null && variants.ContainerImage.isHeader[isHeader],
      className
    )}
    {...rest}>
    {children}
  </figure>
);

export const CaptionImage = ({ children, ...rest }: ComponentPropsWithoutRef<'figcaption'>) => (
  <figcaption
    className="absolute bottom-4 right-4 max-w-[calc(100%-32px)] bg-[rgb(23,23,23)]/50 px-2 py-1 text-center text-[0.625rem] text-xs font-light text-mglGray300 before:text-mglGray300 before:content-['Imagem:_'] [&_a]:cursor-pointer [&_a]:text-mglBlue600 [&_a]:text-opacity-100 [&_a]:no-underline [&_a]:shadow-[inset_0_-8px_0_0_rgb(39_169_225_/_10%)] [&_a]:transition-all [&_a]:duration-500 hover:[&_a]:shadow-[inset_0_-1.15em_0_rgb(39_169_225_/_10%)]"
    {...rest}>
    {children}
  </figcaption>
);
