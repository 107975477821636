/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unknown-property */
import Head from 'next/head';
import useAmpComponent from '@/hooks/useAmpComponent';
import { getCurrentHub } from '@sentry/nextjs';
import { ComponentProps } from '../ContentComposer';
import { useAmp } from 'next/amp';
import { editorialCategoryType } from '@/lib/sponsors';

type HeaderImageProps = {
  url: string;
  caption: string | string[] | ComponentProps[];
  srcset?: { [key: number]: string };
  editorialCategory?: editorialCategoryType;
  isHeader?: 'internal' | 'full';
};

export const srcSetDictionary = {
  320: 320,
  640: 640,
  768: 960,
  1024: 1280,
  1200: 1920,
};

export default function HeaderImage({ url, caption, srcset, editorialCategory = undefined, isHeader = undefined }: HeaderImageProps) {
  const isAmp = useAmp();
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const headerImageSpan = currentSpan && currentSpan.startChild({ op: 'headerImage' });
  const imageProps = {};
  const fetchPriorityProps = isAmp ? {} : ({ fetchpriority: 'high' } as const);

  if (srcset) {
    Object.assign(imageProps, {
      imagesizes: '(max-width: 992px) 100vw, 60vw',
      imagesrcset: Object.keys(srcset).map(e => ` ${srcset[e]} ${srcSetDictionary[e]}w`),
    });
  }

  const { HeaderImageComponent } = useAmpComponent();

  return (
    <div className="mx-0 mb-6 mt-0 w-full">
      <Head>
        <link rel="preload" as="image" href={url} {...imageProps} {...fetchPriorityProps} />
      </Head>
      <HeaderImageComponent
        src={url}
        alt={caption?.toString()}
        figcaption={caption}
        srcset={srcset}
        editorialCategory={editorialCategory}
        isHeader={isHeader}
      />
      {headerImageSpan && headerImageSpan.finish()}
    </div>
  );
}
