/* eslint-disable react/destructuring-assignment */
import useAmpComponent from '@/hooks/useAmpComponent';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import PageDataContext from '@/contexts/pageData';
import { ComponentProps } from '../ContentComposer';
import { AspasI, CitacaoI, EmbedI, GalleryI, ImageI, ProsAndConsI, ZoomOfferI } from './interfaces';

const DealsContent = dynamic(() => import('@/components/Layout/DealsBox/DealsContent'), { ssr: false });
export interface CustomComponentComposerProps {
  partHtml: GalleryI | ImageI | EmbedI | CitacaoI | AspasI | ProsAndConsI | ZoomOfferI;
  index: number;
  titulo?: string;
}

const customComponentTypes = ['embed', 'pros-e-contras', 'imagem', 'citacao', 'aspas', 'galeria', 'oferta-zoom'];

export const isCustomComponent = (
  partHtml: ComponentProps | GalleryI | ImageI | EmbedI | CitacaoI | AspasI | ProsAndConsI
): partHtml is GalleryI | ImageI | EmbedI | CitacaoI | AspasI | ProsAndConsI => customComponentTypes.includes(partHtml.tipo);

export default function CustomComponentComposer({ partHtml, index, titulo = '' }: CustomComponentComposerProps) {
  const state = useContext(PageDataContext);

  const { ImageComponent, IFrameYoutube, ProsAndCons, ContentBlockquote, Gallery, SocialMediaEmbed } = useAmpComponent();

  switch (partHtml.tipo) {
    case 'imagem': {
      const caption =
        partHtml?.legenda && partHtml?.legenda.length > 0 && typeof partHtml?.legenda === 'string'
          ? partHtml?.legenda
          : Array.isArray(partHtml?.legenda) && partHtml?.legenda[0];
      return (
        <div className="mx-0 mb-6 mt-0 w-full">
          <ImageComponent
            src={partHtml.url}
            blurhash={partHtml?.blurhash}
            figcaption={partHtml?.legenda}
            alt={partHtml?.alt || (typeof caption === 'string' && caption) || titulo}
          />
        </div>
      );
    }
    case 'pros-e-contras': {
      return <ProsAndCons pros={partHtml.pros} cons={partHtml.contras} key={`pros-and-cons-${index}`} />;
    }
    case 'citacao': {
      return <ContentBlockquote key={`blockquote-${index}`} author={typeof partHtml.autor === 'string' && partHtml.autor} text={partHtml?.valor} />;
    }
    case 'galeria': {
      return <Gallery key={`gallery-${index}`} valor={partHtml?.valor} />;
    }
    case 'embed': {
      if (partHtml.origem === 'youtube') {
        return <IFrameYoutube key={`video-${index}`} videoTitle="" videoId={partHtml.id} isInternal timestamp={partHtml.timestamp} />;
      }
      return (
        <SocialMediaEmbed
          type={partHtml.origem}
          key={`${partHtml.origem}-${index}`}
          id={partHtml.id}
          hideDataConversation={partHtml.origem === 'twitter' && partHtml.hideDataConversation === '1'}
          category={partHtml?.categoria}
          author={partHtml?.autor}
        />
      );
    }
    case 'oferta-zoom': {
      if (state.pageData?.data?.precos && state.pageData?.data?.precos.length > 0) {
        return <DealsContent deals={state.pageData.data.precos.slice(0, 3)} produto={state.pageData.data.precos[0].produto} />;
      }
      return <></>;
    }
    default:
      return <></>;
  }
}
