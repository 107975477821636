import { useAmp } from 'next/amp';
import HeaderImageComponent from '@/components/Internals/HeaderImage/Image';
import AmpCustomComponentComposer from '@/components/Internals/CustomComponentComposer';
import dynamic from 'next/dynamic';

const AdsGeneratorMemo = dynamic(() => import('@/components/Ads/Google/AdsTypeGeneratorsMemo'));
const ImageComponent = dynamic(() => import('@/components/Layout/Image'));
const Navbar = dynamic(() => import('@/components/Navbar').then(mod => mod.Navbar));

const AmpIFrameYoutube = dynamic(() => import('@components/Amp/IFrameYoutube'));
const AmpNavbar = dynamic(() => import('@components/Amp/Navbar'));
const AmpSocialMediaEmbed = dynamic(() => import('@components/Amp/SocialMediaEmbed'));
const AmpContentBlockquote = dynamic(() => import('@components/Amp/Layout/CustomComponents').then(mod => mod.AmpContentBlockquote));
const AmpProsAndCons = dynamic(() => import('@components/Amp/Layout/CustomComponents').then(mod => mod.AmpProsAndCons));
const AmpImageComponent = dynamic(() => import('@components/Amp/ImageComponent'));
const AmpIcon = dynamic(() => import('@components/Amp/Icon'));
const AmpGallery = dynamic(() => import('@components/Amp/Layout/Gallery'));

const ProsAndCons = dynamic(() => import('@components/Layout/ProsAndCons'), { ssr: false });
const Gallery = dynamic(() => import('@components/Internals/Gallery'), { ssr: false });
const ContentBlockquote = dynamic(() => import('@components/Layout/ContentBlockquote'), { ssr: false });
const SocialMediaEmbed = dynamic(() => import('@/components/Layout/SocialMediaEmbed'), { ssr: false });
const IFrameYoutube = dynamic(() => import('@/components/Layout/IFrameYoutube'), { ssr: false });
const CustomComponentComposer = dynamic(() => import('@/components/Internals/CustomComponentComposer'), { ssr: false });

export default function useAmpComponent() {
  const isAmp = useAmp();

  const AmpComponents = {
    ImageComponent: AmpImageComponent,
    HeaderImageComponent: AmpImageComponent,
    IFrameYoutube: AmpIFrameYoutube,
    Icon: AmpIcon,
    Navbar: AmpNavbar,
    ProsAndCons: AmpProsAndCons,
    Gallery: AmpGallery,
    ContentBlockquote: AmpContentBlockquote,
    SocialMediaEmbed: AmpSocialMediaEmbed,
    CustomComponentComposer: AmpCustomComponentComposer,
  };

  const CommonComponents = {
    ImageComponent,
    HeaderImageComponent,
    IFrameYoutube,
    Icon: AmpIcon,
    AdsGeneratorMemo,
    Navbar,
    ProsAndCons,
    Gallery,
    ContentBlockquote,
    SocialMediaEmbed,
    CustomComponentComposer,
  };

  return isAmp ? AmpComponents : CommonComponents;
}
