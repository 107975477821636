/* eslint-disable import/prefer-default-export */
import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  PageWrapper: {
    ads: {
      true: 'mt-[100px] [@media_only_screen_and_(min-width:426px)_and_(max-width:827px)]:mt-[31.22vw] [@media_only_screen_and_(min-width:828px)]:mt-[250px]',
      false: '',
    },
  },
} as const;

export const PageWrapper = ({ ads, fullScreen, children, ...rest }: ComponentPropsWithoutRef<'main'> & { ads?: boolean; fullScreen?: boolean }) => {
  return (
    <main
      className={classNames(
        'relative mx-auto my-0 block  items-center',
        ads != undefined && variants.PageWrapper.ads[ads.toString()],
        fullScreen
          ? 'max-w-full p-0'
          : 'max-w-[1000px] pb-[51px]  phone:pb-0 extraLargeDesktop:max-w-[calc(1000px+100vw-1360px)] [@media_only_screen_and_(min-width:1960px)]:max-w-[1600px]'
      )}
      {...rest}>
      {children}
    </main>
  );
};
