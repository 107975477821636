// @refresh reset
import React from 'react';
import dynamic from 'next/dynamic';
import { AllAdOptions } from '@/lib/ads';

export interface AdGeneratorProps {
  type: AllAdOptions;
  idCountPosition?: string;
  marginBottomP?: string;
  isPlaceholder?: boolean;
}

export function AdsGenerator({ type, idCountPosition = '', marginBottomP = '', isPlaceholder = false }: AdGeneratorProps) {
  const AdsTypeGenerators = dynamic(() => import('@/components/Ads/Google/AdsTypeGenerators'), { ssr: false });
  return isPlaceholder ?
    <div className="m-auto mb-8 text-center text-mglGray300 bg-mglGray200 p-[100px] max-w-[350px]">PUBLICIDADE</div> :
    <AdsTypeGenerators type={type} idCountPosition={idCountPosition} marginBottomP={marginBottomP} />;
}

const AdsGeneratorMemo = React.memo(AdsGenerator, () => true);

export default AdsGeneratorMemo;
