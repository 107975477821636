import { useEffect, useState } from 'react';

export default function useBreakpoint(
  breakpoint: 'smallPhone' | 'phone' | 'tablet' | 'desktop' | 'largeDesktop',
  { initialState = undefined }: { initialState?: boolean } = {}
) {
  const options = { smallPhone: '426px', phone: '767px', tablet: '992px', desktop: '1200px', largeDesktop: '1360px' } as const;
  const [isTabletOrMobile, setIsTabletOrMobile] = useState(initialState);

  useEffect(() => {
    if (window.matchMedia(`(max-width: ${options[breakpoint]})`).matches) setIsTabletOrMobile(true);
    else setIsTabletOrMobile(false);
  }, []);

  return [isTabletOrMobile, setIsTabletOrMobile] as const;
}
