import getEnv from '@/utils/getEnv';
import { useRouter } from 'next/router';

type AdFlagOption = 'google' | 'mgid' | 'truvid' | 'cazamba' | 'membrana';

export default function useScriptFlag({ adFlag }: { adFlag: AdFlagOption }) {
  const router = useRouter();

  if (getEnv('ENVIRONMENT') === 'production') return true;

  const flags = {
    google: 'google',
    mgid: 'mgid',
    truvid: 'truvid',
    cazamba: 'cazamba',
    membrana: 'membrana',
  } as const;

  if (flags[adFlag] in router.query) {
    if (router.query[flags[adFlag]] === '0') return false;
  }

  return true;
}
